.element {
  background: url("../../assets/footer/BG_Element.png"), #009d4d;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer {
  background: url("../../assets/ticket/BG-Element.png"), #f5f5f5;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
